<template>
  <base-modal
    :is-open="isOpen"
    :modal-title="modalTitle"
    size="md"
    @onClose="$emit('onClose')"
    titleSubmit="Integrate"
    :useCancel="true"
    :forceLoading="modal.loading"
    @onSubmit="submitForm"
  >
    <div class="container py-3 px-4">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center mb-2 p-2 rounded-3">
            <form @submit.prevent="submitForm">
              <div class="row g-3 d-flex flex-column">
                <label
                  >You will be directed to your Facebook Business Manager.
                  Complete all steps to Integration.</label
                >
                <div
                  class="form-group col-lg-12"
                  v-if="!form.userAccessToken && !form.pageList.length"
                >
                  <button class="btn btn-primary" type="button" @click="login">
                    <i class="ri-facebook-circle-fill fs-12"></i>
                    Login with Facebook
                  </button>
                </div>
                <div class="form-group col-lg-12" v-if="form.pageList.length">
                  <label>Page: </label>
                  <v-select
                    placeholder="Select page"
                    label="name"
                    :options="form.pageList"
                    v-model="form.page"
                    @option:selected="selectPage"
                    :loading="pageLoading"
                  ></v-select>
                </div>
                <div class="form-group col-lg-12 mt-3">
                  <label class="form-label">Bot</label>
                  <v-select
                    label="name"
                    name="bot"
                    class="bg-white"
                    v-model="this.bot"
                    :placeholder="`Select bot`"
                    :options="botList"
                    @option:selected="changeValueBotList"
                  />
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="form-label">Business Unit</label>
                  <v-select
                    label="label"
                    name="bussines_unit"
                    class="bg-white"
                    v-model="this.business_unit"
                    :placeholder="`Select business unit`"
                    :options="businessUnit"
                    @option:selected="changeValueBusinessUnit"
                  />
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="form-label">Group</label>
                  <v-select
                    label="label"
                    name="group"
                    class="bg-white"
                    v-model="this.group"
                    :placeholder="`Select group`"
                    :options="groupManagement"
                    @option:selected="changeValueGroup"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>
<script>
import { isEmpty, debounce } from "lodash";
import { AccountService } from "@/services";
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    formFb: {
      type: Object,
    },
    modalTitle: {
      type: String,
      default: "Add Facebook Integration",
    },
  },
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({
      botList: "bot/botList",
      businessUnit: "ticket/getBranchOffice",
      groupManagement: "ticket/getGroupManagement",
    }),
    localStorage() {
      return this.$store.getters.localStorage;
    },
  },
  data() {
    return {
      // isOpen: this.showModal,
      isEmpty,
      data: {},
      fbVersion: "v13.0",
      facebook: {
        data: null,
        isLogin: false,
      },
      form: {
        userAccessToken: null,
        pageList: [],
        pageAccessToken: null,
        pageId: null,
        status: false,
        page: null,
        accountName: null,
        accountId: null,
        pageName: null,
        channelName: "facebook",
        business_unit_id: null,
        bot_id: null,
        group_id: null,
      },
      formData: {},
      submitLoading: false,
      integrationId: null,
      pageLoading: false,
      business_unit: null,
      bot: null,
      group: null,
      modal: {
        delete: false,
        loading: false,
      },
    };
  },
  watch: {
    showModal(newVal) {
      // console.log("oy");
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
    formFb: {
      handler(newVal) {
        if (newVal) {
          let array = [];
          array[0] = newVal.integration_data.page;
          this.form = { ...this.form, ...newVal };
          this.form.pageList = array;
          this.business_unit = newVal.business_unit
            ? newVal.business_unit.branch_office
            : null;
          this.group = newVal.group ? newVal.group.name : null;
          this.bot = newVal.bot ? newVal.bot.name : null;
        }
      },
    },
  },
  methods: {
    changeValueBotList(val) {
      // console.log("bot:", val);
      this.bot = val.name;
      this.form.bot_id = val.id;
      this.formData.bot = val.name;
    },
    changeValueBusinessUnit(val) {
      // console.log("business_unit:", val);
      this.business_unit = val.label;
      this.form.business_unit_id = val.id;
      this.formData.business_unit = val.label;
    },
    changeValueGroup(val) {
      // console.log("group:", val);
      this.group = val.label;
      this.form.group_id = val.value;
      this.formData.group = val.label;
    },
    async logoutFB() {
      FB.logout((response) => {
        // console.log("logoutFB : ", response);
      });
    },
    async checkLoginStatus() {
      // console.log("getLoginStatus");
      FB.getLoginStatus((response) => {
        this.facebook.data = response;
        // console.log("getLoginStatus : ", response);
        if (response.status == "connected") {
          this.facebook.isLogin = true;
          this.getLongLivedUserToken();
        }
      });
    },
    async getLongLivedUserToken() {
      this.pageLoading = true;
      let response = await this.$axios
        .get(`/api/integration/instagram/get-longlived-token`, {
          params: {
            userAccessToken: this.form.userAccessToken,
          },
        })
        .catch((error) => {
          // console.log("error getLongLivedUserToken : ", error);
          this.errorToast(error);
        });
      // console.log("getLongLivedUserToken : ", response);
      this.form.userAccessToken = response.data.access_token;
      await this.getUserPages(); // get user pages after getlonglivedusertoken
      this.pageLoading = false;
    },
    async getUserPages() {
      // get pages belum bisa di get krn izin nya belum di acc
      let responsePages = await this.$axios
        .get("https://graph.facebook.com/" + this.fbVersion + "/me/accounts", {
          params: {
            access_token: this.form.userAccessToken,
          },
        })
        .catch((error) => {
          this.errorToast(error.response.data.error.message);
          // console.log(
          //   "error getUserPages : ",
          //   error.response.data.error.message
          // );
        });
      // console.log("getUserPages : ", responsePages);
      this.form.pageList = responsePages.data.data;
      // console.log("pageList", this.form.pageList);
    },
    async selectPage() {
      // console.log("selected", this.form.page);
      this.form.pageAccessToken = this.form.page.access_token;
      this.form.pageId = this.form.page.id;
      this.form.pageName = this.form.page.name;
    },
    async generateMe() {
      let response = await this.$axios
        .get(`https://graph.facebook.com/${this.fbVersion}/me`, {
          params: {
            access_token: this.form.userAccessToken,
          },
        })
        .catch((error) => {
          this.errorToast(error.response.data.error.message);
          // console.log(
          //   "error getUserPages : ",
          //   error.response.data.error.message
          // );
        });

      this.form.accountId = response.data.id;
      this.form.accountName = response.data.name;
      // console.log("generate me", response);
    },
    toggleDisconnect() {
      this.modal.delete = true;
    },
    async disconnectChannel() {
      this.modal.loading = true;
      try {
        let response = await this.$axios.delete(
          `api/${this.$store.getters.appId.hashed}/v3/remove-integration`,
          {
            params: {
              integrationId: this.integrationId,
            },
          }
        );
        const res = response.data;
        this.modal.loading = false;
        this.modal.delete = false;
        // this.emitter.$emit("hideIntegrationModal");
        this.successToast("Channel disconected");
        this.form.status = false;
        await this.clearForm();
        // document.location.reload();
      } catch (error) {
        this.modal.loading = false;
        this.modal.delete = false;
        this.form.status = !this.form.status;
        this.errorToast(error);
      }
    },
    async submitForm() {
      this.submitLoading = true;
      try {
        let formData = {
          ...this.form,
          status: true,
        };
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/integrate`,
          formData
        );
        // console.log("hei tayo", response);
        this.form.status = true;
        this.form.channelName = "facebook";
        this.form.bot_id = formData.bot_id ? formData.bot_id : null;
        this.form.business_unit_id = formData.business_unit_id
          ? formData.business_unit_id
          : null;
        this.form.group_id = formData.group_id ? formData.group_id : null;
        this.submitLoading = false;
        // this.integrationId = response.data.data.integration.id;
        // this.emitter.$emit("hideIntegrationModal");
        this.successToast(response.data.message);
        this.logoutFB();
        this.$emit("onAdd");
        this.clearForm();
        this.$emit("onClose");
      } catch (error) {
        // console.log("error", error.message);
        // console.log("error", error.response.data.message);
        this.submitLoading = false;
        this.errorToast(error.response.data.message);
        this.logoutFB();
        this.$emit("onClose");
      }
    },
    async clearForm() {
      this.form.userAccessToken = null;
      this.form.pageList = [];
      this.form.page = [];
      this.form.pageAccessToken = null;
      this.form.pageId = null;
      this.form.status = false;
      this.form.accountId = null;
      this.form.accountName = null;
      this.form.bot_id = null;
      this.bot = null;
      this.form.business_unit_id = null;
      this.business_unit = null;
      this.form.group_id = null;
      this.group = null;
    },
    async login() {
      const scope = [
        "pages_manage_metadata",
        "pages_read_engagement",
        "pages_show_list",
        "pages_messaging",
        "pages_manage_engagement",
        "pages_read_user_engagement",
      ];
      let account = await AccountService.login(scope.join(","));
      if (account) {
        this.form.userAccessToken = account.accessToken;
        await this.generateMe();
        // this.getUserPages();
        this.getLongLivedUserToken();
      }
    },
    async toggleIntegration(data) {
      let param = {
        integrationId: data.id,
        status: data.status,
      };
      this.submitToggle(param, this);
    },
    submitToggle: debounce(async (param, vm) => {
      await vm.$store.dispatch("integration/enableDisableIntegration", param);
      vm.fetchData();
    }, 300),
  },
  async mounted() {
    if (typeof FB !== "undefined") {
      await this.checkLoginStatus();
    }
    // this.fetchData();
  },
};
</script>
