const broadcastRouteEmailBlast = {
  path: "/:appId/campaign",
  name: "campaign",
  component: () => import("@/views/campaign/pages/CampaignMain.vue"),
  redirect: { name: "campaign.contact" },
  props: true,
  meta: { title: "Campaign" },
  children: [
    {
      path: "broadcast/email",
      name: "campaign.broadcast.email",
      component: () => import("@/views/campaign/pages/broadcast/Email.vue"),
      meta: { title: "Broadcast Email" },
    },
    {
      path: "broadcast/calendar",
      name: "campaign.broadcast.calendar",
      component: () => import("@/views/campaign/pages/broadcast/Calendar.vue"),
      meta: { title: "Calendar" },
    },
    {
      path: "broadcast/sms",
      name: "campaign.broadcast.sms",
      component: () => import("@/views/campaign/pages/broadcast/Sms.vue"),
      meta: { title: "Broadcast SMS" },
    },
    {
      path: "broadcast/whatsapp",
      name: "campaign.broadcast.whatsapp",
      // component: () => import("@/views/campaign/pages/broadcast/Whatsapp.vue"),
      component: () => import("@/views/whatsapp/pages/WhatsappMain.vue"),
      meta: { title: "Broadcast Whatsapp" },
    },
    {
      path: "template/email",
      name: "campaign.template.email",
      component: () => import("@/views/campaign/pages/template/Email.vue"),
      meta: { title: "Broadcast Template Email" },
    },
    {
      path: "broadcast/email/create",
      name: "campaign.broadcast.email.create",
      component: () =>
        import("@/views/campaign/pages/broadcast/EmailCreate.vue"),
      meta: { title: "Broadcast Email Create" },
    },
    {
      path: "broadcast/email/update/:broadcastId",
      name: "campaign.broadcast.email.update",
      component: () =>
        import("@/views/campaign/pages/broadcast/EmailUpdate.vue"),
      meta: { title: "Broadcast Email Update" },
    },
    {
      path: "template/email/create",
      name: "campaign.template.email.create",
      component: () =>
        import("@/views/campaign/pages/template/EmailCreate.vue"),
      meta: { title: "Create Broadcast Template Email" },
    },
    {
      path: "template/sms",
      name: "campaign.template.sms",
      component: () => import("@/views/campaign/pages/template/Sms.vue"),
      meta: { title: "Broadcast Template SMS" },
    },
    {
      path: "template/whatsapp",
      name: "campaign.template.whatsapp",
      // component: () => import("@/views/campaign/pages/template/Whatsapp.vue"),
      component: () => import("@/views/whatsapp/pages/WhatsappTemplate.vue"),
      meta: { title: "Broadcast Template Whatsapp" },
    },
    {
      path: "contact",
      name: "campaign.contact",
      component: () => import("@/views/campaign/pages/Contact.vue"),
      meta: { title: "Contact" },
    },
    {
      path: "contact/:contactId",
      name: "campaign.contact.detail",
      component: () => import("@/views/campaign/pages/ContactDetail.vue"),
      meta: { title: "Contact Detail" },
      match: true,
    },
    {
      path: "group",
      name: "campaign.group",
      component: () => import("@/views/campaign/pages/Group.vue"),
      meta: { title: "Group" },
    },
    {
      path: "import",
      name: "campaign.import",
      component: () => import("@/views/campaign/pages/Import.vue"),
      meta: { title: "Import" },
    },
    {
      path: "dashboard",
      name: "campaign.analytic.dashboard",
      component: () => import("@/views/campaign/pages/analytics/Dashboard.vue"),
      meta: { title: "Import" },
    },
    {
      path: "template/email/create/builder",
      name: "campaign.template.email.create.builder",
      component: () => import("@/views/campaign/pages/DragDrop.vue"),
      meta: { title: "Template Builder" },
    },
    {
      path: "template/email/create/type",
      name: "campaign.template.email.create.type",
      component: () => import("@/views/campaign/pages/template/Email.vue"),
      meta: { title: "Template Type" },
    },
    {
      path: "template/email/create/type/:templateId",
      name: "campaign.template.email.create.type.templateId",
      component: () => import("@/views/campaign/pages/DragDrop.vue"),
      meta: { title: "Extend Template" },
    },
    {
      path: "template/email/list",
      name: "campaign.template.email.list",
      component: () => import("@/views/campaign/pages/template/EmailList.vue"),
      meta: { title: "Template List" },
    },
    {
      path: "template/email/update/:templateId",
      name: "campaign.template.email.update.templateId",
      component: () =>
        import("@/views/campaign/pages/template/EmailCreate.vue"),
      meta: { title: "Template List" },
    },
    {
      path: "broadcast/email/detail/:broadcastId",
      name: "campaign.broadcast.email.detail.broadcastId",
      component: () =>
        import("@/views/campaign/pages/broadcast/BroadcastAnalytic.vue"),
      meta: { title: "Broadcast Analytic" },
    },
  ],
};

export default broadcastRouteEmailBlast;
